import { render, staticRenderFns } from "./sourcingso-inquiry-mobile.vue?vue&type=template&id=f9c95dcc&scoped=true&"
import script from "./sourcingso-inquiry-mobile.vue?vue&type=script&lang=js&"
export * from "./sourcingso-inquiry-mobile.vue?vue&type=script&lang=js&"
import style1 from "./sourcingso-inquiry-mobile.vue?vue&type=style&index=1&id=f9c95dcc&lang=less&scoped=true&"
import style2 from "./sourcingso-inquiry-mobile.vue?vue&type=style&index=2&id=f9c95dcc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9c95dcc",
  null
  
)

export default component.exports
<template>
  <div>
    <Modal class="pop-bulk" footer-hide v-model="showInquiry">
      <div class="top">
        Group Submit products for sourcing
      </div>
      <form action="" name="">
        <div class="content">
          <div class="template">
            Click here to
            <span
              ><a
                href="https://api-staging.connexima.com/file/bulk-upload-product-for-sourcing.xls"
                target="_blank"
                >download the template.</a
              ></span
            >
          </div>
          <div class="upload">
            <input type="file" class="file" @change="getFile($event, 'file')" />
          </div>
        </div>
        <div class="btn">
          <div class="cancel search-button" @click="cancel()">Cancel</div>
          <div
            class="upload search-button"
            @click="save($event)"
            v-if="!loading"
          >
            Submit
          </div>
          <div class="upload uploading" v-else>Submitting</div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import axios from "axios";
import { API_PREFIX } from "@/config";

export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showInquiry: false,
      loading: false,
      formData: new FormData()
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {},
  methods: {
    getFile(event, input_file_name) {
      this.formData.append(input_file_name, event.target.files[0]);
    },
    cancel() {
      this.showInquiry = false;
    },
    save(event) {
      this.loading = true;
      event.preventDefault();
      axios.defaults.baseURL = API_PREFIX;
      const accessToken = store.getters.token;
      for (let i in this.param) {
        this.formData.append(i, this.param[i]);
      }
      let headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`
      };
      axios({
        method: "POST",
        url: "/sourceInquiry/inquiryBatchUpload",
        data: this.formData,
        headers: headers
      })
        .then(function() {})
        .catch(function(err) {
          console.log(err);
        })
        .finally(() => {
          this.$Message.success("success");
          this.$emit("getSourcings");
          this.loading = false;
          this.showInquiry = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.pop-bulk {
  .top {
    color: #23262f;
    font-size: 24px;
    font-weight: 600;
  }
  .template {
    padding: 20px 0;
    span {
      font-weight: 600;
      a {
        color: #f57f45;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .upload {
      background: #f57f45;
      color: #fff;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .uploading {
      background: #ffb894;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
</style>

<style lang="less">
.pop-bulk {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
